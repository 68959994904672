<template>
  <div class="bgcard">工单详情</div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.bgcard {
  height: 100%;
  width: 100%;
  border: 0px;
  box-sizing: border-box;
  padding: 19px 17px;
  border-radius: 2px;
  background: rgba(034, 038, 040, 0.76);
  padding: 27px 34px 0px 38px;
}
</style>
